import { transitions, positions, Provider as AlertProvider } from "react-alert";
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  type: "success",
  containerStyle: {
    zIndex: 1031,
  },
  timeout: 3000,
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
export default options;
