const firebaseConfig = {
  apiKey: "AIzaSyAtGKOCyyMtd-mDz_bSNqW1Ys39AhFGvbk",
  authDomain: "seagull-bb726.firebaseapp.com",
  projectId: "seagull-bb726",
  storageBucket: "seagull-bb726.appspot.com",
  messagingSenderId: "595746275071",
  appId: "1:595746275071:web:c289b5d2ed29fdd87dd6ca",
  api:"https://seagull-bb726-default-rtdb.firebaseio.com/"
};

export default firebaseConfig;