import {
  BANNER_IMAGE,
  GET_MARQUEE,
  GET_HEADER_PRODUCT,
  GET_PRODUCT_DETAIL,
  LOADING,
  GET_OFFERS
} from './type';
import firebase from 'firebase';
import axios from 'axios';
import firebaseConfig from '../config/env'
  
  

  export const getBanner =() =>{
        return (dispatch)=>{
          dispatch(loading());
          axios.get(`${firebaseConfig.api}/Banner.json`)
        .then(async doc=>{
          dispatch(bannerResponse(doc.data))
        })
        .catch(err=>console.error(err))
            }
          }

  export const getMarquee= () => {
    return (dispatch)=>{
      dispatch(loading());
      axios.get(`${firebaseConfig.api}/Marquee.json`)
      .then(async doc=>{
        dispatch(marqueeResponse(doc.data))
      })
      .catch(err=>console.error(err))
          }
    }

    export const getHeaderProducts= () => {
      return (dispatch)=>{
        dispatch(loading());
        axios.get(`${firebaseConfig.api}/HeaderProducts/ProductNames.json`)
        .then(async doc=>{
          dispatch(HeaderProductsResponse(doc.data))
        })
        .catch(err=>console.error(err))
            }
      }

    export const getProduct= (id) =>{
      return (dispatch)=>{
        dispatch(loading());
        axios.get(`${firebaseConfig.api}/Products/${id}.json`)
        .then(async doc=>{
          dispatch(ProductsResponse(doc.data))
        })
        .catch(err=>console.error(err))
            }
    }

    export const getOffers= (id) =>{
      return (dispatch)=>{
        dispatch(loading());
        axios.get(`${firebaseConfig.api}/Offers.json`)
        .then(async doc=>{
          dispatch(Offers(doc.data))
        })
        .catch(err=>console.error(err))
            }
    }

export const loading = () =>{
      return {
          type:LOADING
  }
}

export const bannerResponse = (responce) =>{
  return {
      type:BANNER_IMAGE,
      payload:responce 
  }
}

export const marqueeResponse = (responce) =>{
  return {
      type:GET_MARQUEE,
      payload:responce 
  }
}

export const HeaderProductsResponse = (responce) =>{
  return {
      type:GET_HEADER_PRODUCT,
      payload:responce 
  }
}

export const ProductsResponse = (responce) =>{
  return {
      type:GET_PRODUCT_DETAIL,
      payload:responce 
  }
}

export const Offers=(responce) =>{
  return {
      type:GET_OFFERS,
      payload:responce 
  }
} 


 




 


