import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { connect } from "react-redux";
import { getMarquee, getHeaderProducts, getOffers } from "../redux/action";
import Scrollspy from "react-scrollspy";

const Header = (props) => {
  const [scrollChange, setScrollChange] = useState(false);
  const [isDisplay, setDisplay] = useState({
    marquee: {
      display: false,
      content: "",
    },
    offers: false,
    header: [],
  });
  const location = useLocation();

  useEffect(() => {
    props.getMarqueeDetails();
    props.getHeaderProductsDetails();
    props.getOffersProducts();
  }, []);

  useEffect(() => {
    if (props.offersPayload) {
      setDisplay({ ...isDisplay, offers: props.offersPayload });
    }
  }, [props.offersPayload]);

  useEffect(() => {
    if (props.marqueePayload) {
      setDisplay({ ...isDisplay, marquee: props.marqueePayload });
    }
  }, [props.marqueePayload]);

  useEffect(() => {
    if (props.headerProductsPayload) {
      let products = props.headerProductsPayload.split(",");
      products.sort();
      setDisplay({ ...isDisplay, header: products });
    }
  }, [props.headerProductsPayload]);

  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setScrollChange(true);
    } else {
      setScrollChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const closeNavBar = () => {
    const navbarToggle =  document.getElementsByClassName('navbar-toggler');
    if (navbarToggle[0].ariaExpanded === 'true') {
      navbarToggle[0].click();
    }
  }

  const timeout = () => {
    const navbarToggle =  document.getElementsByClassName('navbar-toggler');
    if (navbarToggle[0].ariaExpanded === 'true') {
      setTimeout(closeNavBar, 1000);
    }
  }

  return (
    <header className={`sticky-top ${scrollChange ? "affix" : ""}`}>
      <div className="nav-info">
        <div className="container-xl py-1 px-4 px-xl-3">
          <div className="contact d-none d-xl-block">
            <a
              href="mailto:seagull.swaram@gmail.com"
              className="color-white text-decoration-none"
            >
              seagull.swaram@gmail.com
            </a>
            &nbsp;|&nbsp;
            <a href="tel:+919442101335" className="text-decoration-none">
              +91 9150317689
            </a>
          </div>
          <div className="nav-marquee">
            {isDisplay.marquee.display ? (
              <Marquee speed={40} direction="left">
                {isDisplay.marquee.content}
              </Marquee>
            ) : (
              ""
            )}
          </div>
          <div className="social-links d-none d-xl-grid">
            <a
              href="https://www.facebook.com/people/Geetha-KR/100080329303450/?sk=grid"
              target="_blank"
            >
              <img src={require("../assets/img/fb.svg").default} alt="logo" />
            </a>
            <a href="https://www.facebook.com" target="_blank">
              <img
                src={require("../assets/img/insta.svg").default}
                alt="logo"
              />
            </a>
            <a href="https://www.facebook.com" target="_blank">
              <img
                src={require("../assets/img/linkedin.svg").default}
                alt="logo"
              />
            </a>
            <a href="https://twitter.com/SEAGULLELECTRI1" target="_blank">
              <img
                src={require("../assets/img/twitter.svg").default}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>

      <nav
        className={`navbar navbar-expand-md navbar-light ${
          scrollChange ? "affix" : ""
        }`}
      >
        <div className="container-xl px-4 px-xl-3">
          <span className="navbar-brand py-0">
            <NavLink to="/" onClick={closeNavBar}>
              <img
                src={require("../assets/img/logo.png").default}
                alt="logo"
                className={`${
                  !scrollChange && location.pathname !== "/" ? "light-logo" : ""
                }`}
              />
            </NavLink>
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#appnav"
            aria-controls="appnav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="appnav">
            <ul className="navbar-nav ms-auto mb-lg-0">
              <li class="nav-item" onClick={closeNavBar}>
                {location.pathname == "/" ? (
                  <a
                    onClick={() => window.scrollTo(0, 0)}
                    className="nav-link no-bar"
                  >
                    Home
                  </a>
                ) : (
                  <NavLink to="/" exact className="nav-link no-bar">
                    Home
                  </NavLink>
                )}
              </li>
              <li className="nav-item dropdown has-megamenu">
                <div
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </div>
                <ul className="dropdown-menu py-0 megamenu dropdown-menu-end">
                  <div className="transparent-navfix"></div>
                  <div className="bg-white py-2">
                    <div className="container-xl">
                      <div className="row">
                        {isDisplay.header.map((headerName, index) => (
                          <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 text-center"
                            key={index}
                            onClick={closeNavBar}
                          >
                            <NavLink
                              key={index}
                              to={/products/ + headerName.replace(/\s+/g, "")}
                              exact
                              className="nav-link"
                            >
                              {headerName}
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <Scrollspy
                className="navbar-nav pt-0"
                items={["About-us", "Quality", "Offers", "Contact-us"]}
                currentClassName="is-current"
                offset={400}
              >
                <li className="nav-item" onClick={timeout}>
                  <a
                    onClick={() => window.location.replace("/#About-us")}
                    className="nav-link"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item" onClick={timeout}>
                  <a
                    onClick={() => window.location.replace("/#Quality")}
                    className="nav-link"
                  >
                    Quality
                  </a>
                </li>
                {isDisplay.offers.display ?
                  <li className="nav-item">
                    <NavLink to='/offers' className="nav-link" onClick={closeNavBar}>
                      Offers
                    </NavLink>
                  </li>
                :
                <li className="nav-item disabled" onClick={timeout}>
                <a onClick={() => window.location.replace("/#Offers")} className="nav-link disabled pe-auto" >
                  Offers
                </a>
              </li>
                }
                <li className="nav-item" onClick={timeout}>
                  <a href="#Contact-us" className="nav-link me-0">
                    Contact Us
                  </a>
                </li>
              </Scrollspy>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    marqueePayload: state.seagull.marquee,
    headerProductsPayload: state.seagull.headerProduct,
    offersPayload: state.seagull.offers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMarqueeDetails: () => {
      dispatch(getMarquee());
    },
    getHeaderProductsDetails: () => {
      dispatch(getHeaderProducts());
    },
    getOffersProducts: () => {
      dispatch(getOffers());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
