import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";
import Loader from "./components/loader";
 
const Landing = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./pages/landing")), 200);
  });
});
const Offers = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./pages/offer")), 200);
  });
});
const Products = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./pages/products")), 200);
  });
});

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Header />
        <main>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/products/:id" component={Products} />
            <Route exact path="/offers" component={Offers} />
          </Switch>
        </main>
        <Footer />
      </Suspense>
      <a onClick={() => window.open("https://wa.me/919150317689", '_blank').focus() } className="whatsapp-link">
        <div className="chat-w">Chat with us</div>
        <img src={require("./assets/img/whatsappIcon.png").default} target="_blank"></img>
      </a>
    </div>
  );
}

export default App;
