import {
  BANNER_IMAGE,
  GET_MARQUEE,
  GET_HEADER_PRODUCT,
  GET_PRODUCT_DETAIL,
  GET_OFFERS,
  LOADING
} from './type'
const InitialValue={
    loader:false
 
}

const contactReducer= (state=InitialValue,action)=>{
 
      switch(action.type){
          case LOADING:
            return{
                ...state,
                loader:true,
            }  
         

            case BANNER_IMAGE:
              return{
                ...state,
                imageBanner:action.payload,
                loader:false, 
            }

            case GET_MARQUEE:
              return{
                ...state,
                marquee:action.payload,
                loader:false, 
            }

            case GET_HEADER_PRODUCT:
            return{
              ...state,
              headerProduct:action.payload,
              loader:false, 
          }

          case GET_PRODUCT_DETAIL:
            return{
              ...state,
              product:action.payload,
              loader:false, 
          }
          case GET_OFFERS:
            return{
              ...state,
              offers:action.payload,
              loader:false, 
          }

          default :
          return state
      }  
  }
  
export default contactReducer;