import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import bootstrap from "bootstrap";
import "./scss/style.scss";
import App from "./App";
import firebase from 'firebase';
import config from './config/env';
import options from './config/toroast';
import {Provider} from 'react-redux';
import store from './redux/store';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
firebase.initializeApp(config);
//options.transition= transitions.SCALE
//options.position= positions.BOTTOM_CENTER
ReactDOM.render(
  <Provider store={store}>
    <Router>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
      </AlertProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
