import {createStore,applyMiddleware,combineReducers} from 'redux';
import Reducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const rootReducer=combineReducers({
    seagull : Reducer,
  });
const store=createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store;