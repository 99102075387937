const Footer = (props) => {
  return (
    <footer className="pt-4 footer" id="Contact-us">
      <div className="container-xl px-4 px-xl-3 py-4">
        <div className="footer-section">
          <div className="footer-col mb-4">
            <span className="navbar-brand fw-bold">SEAGULL ELECTRICALS</span>
            <p className="pt-1">
              Flat No # 1N, <br />
              Sakthi Residency <br />
              Visveswaraiah Street, No#3, <br />
              K.K.Pudur <br />
              COIMBATORE - 641038, INDIA
            </p>

            <p className="mt-2 mb-0 fw-bold">
              <h5 className="footer-head mb-2">Contact</h5>
              <a
                href="mailto:sales@seagullexports.in"
                className="text-decoration-none"
              >
                sales@seagullexports.in
              </a>
              <br />
              <a
                href="mailto:seagull.swaram@gmail.com"
                className="text-decoration-none"
              >
                seagull.swaram@gmail.com
              </a>
            </p>
            <p className="fw-bold mt-3">
              <a href="tel:04224377334" className="text-decoration-none">
                0422 4377 334
              </a>
              <br />
              <a href="tel:+919442101335" className="text-decoration-none">
                +91 9150317689
              </a>
            </p>
            <div className="footer-col">
              <h5 className="footer-head mb-2">Follow us</h5>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/people/Geetha-KR/100080329303450/?sk=grid"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/img/fb.svg").default}
                    alt="logo"
                  />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/img/insta.svg").default}
                    alt="logo"
                  />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/img/linkedin.svg").default}
                    alt="logo"
                  />
                </a>
                <a
                  href="https://twitter.com/SEAGULLELECTRI1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/img/twitter.svg").default}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-col">
            <section>
              <div className="google-map-code">
                <iframe
                  src="https://maps.google.com/maps?q=11.0276033,76.9370212&hl=es;z=14&amp;output=embed"
                  width="100%"
                  height="480"
                  title="gmap"
                  style={{ border: 0 }}
                ></iframe>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-3 text-center text-sm-start">
              Privacy Policy
            </div>
            <div className="col-sm-9 text-center text-sm-end">
              Handcrafted by Seagul. Copyright © 2022. All rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
